import React, { ReactElement } from 'react';
import { InlineTab, InlineTabContent } from '../../components/light/InlineTab';
import { Content } from '../../components/light/Content';
import { OperationVariables, Query, QueryResult } from '@apollo/react-components';
import { gql } from '@apollo/client';
import { PurchaseOrderEdit } from './PurchaseOrderEdit';
import { Loading } from '../../components/Loading';
import { PurchaseOrderCost } from './PurchaseOrderEditCost';
import { PurchaseOrderStage } from './PurchaseOrderStage';
import { PurchaseOrder } from '../../types/PurchaseOrderType';
import { Graph } from '../../generated/graph';
import { PurchaseOrderTransactionScreen, SummaryRowType } from './PurchaseOrderTransactionScreen';

const QUERY = gql`
  query getPurchasOrder($id: Int!) {
    admin {
      id
      superAdmin
    }

    purchaseOrder(id: $id) {
      id
      name
      type
      tags {
        id
        name
      }
      stage
      volume
      orderDetail
      orderFile
      orderAmount
      orderAmountCurrency
      orderQty
      invoiceFile
      shippingFile
      supplierFile
      invoiceAmount
      shippingAmount
      shippingAmountCurrency
      shippingQty

      invoiceNumber
      shippingNumber

      creditType
      creditUnit
      creditAmount
      creditAmountCurrency

      invoiceAt
      createdAt
      shippedAt
      arrivedAt
      processingAt
      completedAt
      voidAt

      createdBy {
        id
        name
        image
      }
      shippedBy {
        id
        name
        image
      }
      arrivedBy {
        id
        name
        image
      }
      processingBy {
        id
        name
        image
      }
      completedBy {
        id
        name
        image
      }
      voidBy {
        id
        name
        image
      }

      paymentMethod
      paymentAt
      paymentFile
      exchangeRate
      paymentBy {
        id
        name
        image
      }
    }
  }
`;

type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

export class PurchaseOrderScreen extends React.Component<Props> {
  state: {
    id: number;
    purchaseOrder?: PurchaseOrder;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      id: Number(this.props.match.params.id),
    };
  }

  render(): ReactElement {
    return (
      <Content>
        <Query
          query={QUERY}
          variables={({ id: this.state.id } as unknown) as OperationVariables}
          fetchPolicy="network-only"
        >
          {this.renderTab}
        </Query>
      </Content>
    );
  }

  renderTab = ({ data, loading }: QueryResult<Graph.Query>): ReactElement => {
    if (data === undefined || data.purchaseOrder === undefined || loading) {
      return <Loading />;
    }

    return (
      <div className="element-wrapper">
        <h6 className="element-header">Purchase Order</h6>
        <div className="row">
          <div className="col-9">
            <div className="element-box">
              <InlineTab>
                <InlineTabContent name="Purchase Order" alias="order">
                  <PurchaseOrderEdit
                    purchaseOrder={data.purchaseOrder as Graph.PurchaseOrder}
                    admin={data.admin as Graph.Admin}
                    id={Number(this.props.match.params.id)}
                  />
                </InlineTabContent>
                <InlineTabContent name="Cost Sheet" alias="cost">
                  <PurchaseOrderCost purchaseOrder={data.purchaseOrder as Graph.PurchaseOrder} />
                </InlineTabContent>
                <InlineTabContent name="Transactions" alias="transaction">
                  <PurchaseOrderTransactionScreen id={Number(this.props.match.params.id)} />
                </InlineTabContent>
              </InlineTab>
            </div>
          </div>

          <div className="col-3">
            <PurchaseOrderStage
              purchaseOrder={data.purchaseOrder as Graph.PurchaseOrder}
              admin={data.admin as Graph.Admin}
            />
          </div>
        </div>
      </div>
    );
  };
}
