import moment from 'moment';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import styles from './tasks.module.scss';
import PrintReceipt from './PrintReceipt';
import Formatter from 'src/libs/Formatter';
import { useMutation } from '@apollo/client';
import Badge from 'src/components/badge/Badge';
import { Graph } from '../../../generated/graph';
import XForm from 'src/components/layout/xform/XForm';
import Button from 'src/components/layout/xform/Button';
import { AdminPicker } from 'src/components/AdminPicker';
import { MUTATION_PACKAGE_CLAIM_DELIVER } from '../query';
import { Table } from 'src/components/layout/tables/Table';
import { Checkbox } from '../../../components/form/FormComponent';
import { Text, Tooltip } from '@shopify/polaris';

interface Schedule {
  title: string;
  items: Graph.Package[];
  onlyThirdParty: boolean;
}

function Schedule(props: Schedule) {
  const [toggle, setToggle] = useState(false);
  const ctd = props?.items.filter(x => !!x.isDropbox).length;
  const hasStock = props?.items.filter(x => !!x.hasStock).length;
  const hasStockWithBarcode = props?.items.filter(x => x.barcode && !!x.hasStock).length;
  const [selectedPackages, setSelectedPackages] = useState<Graph.Package[]>([]);
  const [isCliaming, setIsCliaming] = useState(false);
  const [admin, setAdmin] = useState(0);
  const [print, setPrint] = useState<Graph.Package | null>(null);
  // const [timer, setTimer] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [allPackageReferenceList] = useState<{ [key: number]: any }>({});
  const [claim, { loading }] = useMutation(MUTATION_PACKAGE_CLAIM_DELIVER, {
    refetchQueries: ['PackageList'],
  });
  const refPrint = useRef<{ print: () => void }>(null);

  const onClaim = async (index = 0) => {
    if (selectedPackages.length > 0) {
      if (index === 0) {
        const randomCode = ((1000 + 8000 * Math.random()) | 0) + '';
        const answer = prompt('Please type ' + randomCode);
        if (answer !== randomCode && index === 0) {
          if (answer) alert('You type the wrong verification number');
          return;
        }
      }

      const p = selectedPackages[index];
      const next = index + 1;
      await claim({ variables: { id: p.id, admin } })
        .then(r => {
          if (r.data?.claimDeliveryPackage && next < selectedPackages.length) {
            setIsCliaming(true);
            onClaim(next);
          } else if (next >= selectedPackages.length) {
            setIsCliaming(false);
            setSelectedPackages([]);
          }
        })
        .catch(e => {
          setIsCliaming(false);
          window.alert(e?.message);
        });
    }
  };
  const toggleSellerId = (item: Graph.Package) => {
    const list = [...selectedPackages];
    const index = list.indexOf(item);
    if (index < 0) {
      list.push(item);
    } else {
      list.splice(index, 1);
    }
    setSelectedPackages(list);
  };
  const toggleAll = () => {
    if (selectedPackages.length === props?.items?.length) {
      setSelectedPackages([]);
    } else {
      setSelectedPackages(props?.items);
    }
  };

  const onPrint = (x: Graph.Package) => {
    setPrint(x);
    // setTimer(new Date().getTime().toString());
  };
  const onMassPrinting = async () => {
    selectedPackages.map(p => {
      return allPackageReferenceList[p.id].print();
    });
  };

  if (props.items.length > 0) {
    return (
      <div className="mb-3">
        <div style={{ display: 'none' }}>
          <PrintReceipt
            ref={refPrint}
            item={print}
            onCallBack={() => {
              refPrint?.current?.print();
              setPrint(null);
            }}
          />
          {selectedPackages.map(p => (
            <PrintReceipt
              ref={ref => {
                allPackageReferenceList[p.id] = ref;
              }}
              key={p.id}
              item={p}
            />
          ))}
        </div>
        <Table stripe={true} border={true}>
          <thead>
            <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
              <th colSpan={props.onlyThirdParty ? 8 : 7}>
                <div style={{ display: 'flex' }}>
                  <div style={{ height: '37px', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                    {props.title}
                    <span style={{ fontWeight: 'lighter' }}> ({props.items.length})</span>
                    {props?.items?.find(x => x?.overSize) && (
                      <>
                        &nbsp;
                        <>
                          &nbsp;
                          <Badge type="danger">
                            Over Size&nbsp;
                            <Tooltip
                              preferredPosition="above"
                              width="wide"
                              content="ចំពោះកញ្ចប់ដែលធំពេក Oversize, Controller ត្រូវហៅដៃគូរដឹកជញ្ជូនមកដឹកឬទៅយកកញ្ចប់នេះ"
                            >
                              <Text fontWeight="bold" variant="bodySm" as="span">
                                <i className="fas fa-question-circle"></i>
                              </Text>
                            </Tooltip>
                          </Badge>
                        </>
                      </>
                    )}
                    {ctd > 0 && (
                      <span
                        style={{
                          color: '#eb3b5a',
                          fontSize: '12px',
                        }}
                      >
                        &nbsp; CTD : {ctd}
                      </span>
                    )}
                    {hasStock > 0 && (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'white',
                          fontSize: '11px',
                          backgroundColor: 'orange',
                          lineHeight: '15px',
                          padding: '0 5px',
                          borderRadius: '0px 10px 10px 0px',
                          marginLeft: '4px',
                        }}
                      >
                        &nbsp; EXP : {hasStockWithBarcode} / {hasStock} &nbsp;
                        <Tooltip
                          preferredPosition="above"
                          width="wide"
                          content={`Express: បានភ្ជាប់ BARCODE ចំនួន ${hasStockWithBarcode} / ${hasStock} កញ្ចប់។ 
                          បន្ទាប់ពី attach barcode ហើយត្រូវយកមក Scan In.
                          `}
                        >
                          <Text fontWeight="bold" variant="bodySm" as="span">
                            <i className="fas fa-question-circle"></i>
                          </Text>
                        </Tooltip>
                      </span>
                    )}
                  </div>
                  {!!props?.onlyThirdParty && selectedPackages.length > 0 && (
                    <div
                      style={{ width: '300px', paddingLeft: '50px' }}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <XForm.InputGroup label="">
                        <AdminPicker
                          style={{ flexGrow: 1 }}
                          value={admin}
                          placeholder="None"
                          onChange={v => setAdmin(v)}
                          defaultGroup={5}
                        />
                        <XForm.Button
                          onClick={e => {
                            e.stopPropagation();
                            onClaim();
                          }}
                          loading={loading}
                        >
                          Claim
                        </XForm.Button>
                      </XForm.InputGroup>
                    </div>
                  )}
                </div>
              </th>

              <th colSpan={2} style={{ textAlign: 'right' }}>
                <XForm.Button>
                  {toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </XForm.Button>
                &nbsp;
                {!!props?.onlyThirdParty && selectedPackages.length > 0 && (
                  <Button onClick={onMassPrinting}>
                    Mass print&nbsp;<i className="os-icon os-icon-printer"></i>
                  </Button>
                )}
              </th>
            </tr>
            {toggle && (
              <tr>
                {!!props?.onlyThirdParty && (
                  <th style={{ width: 40 }}>
                    <Checkbox
                      disabled={!!isCliaming}
                      style={{ marginTop: '-10px' }}
                      checked={selectedPackages.length === props.items.length}
                      onChange={toggleAll}
                    />
                  </th>
                )}
                <th style={{ width: 50 }}>Type</th>
                <th style={{ width: 180 }}> Date</th>
                <th style={{ width: 120 }}>Services</th>
                <th>Package</th>
                <th style={{ width: 200 }}>Merchant</th>
                <th style={{ width: 120 }}>Claimed By</th>
                <th style={{ width: 200 }}>Customer</th>
                <th style={{ width: 220 }}>Route</th>
                {!!props?.onlyThirdParty && <th style={{ width: 130 }}></th>}
              </tr>
            )}
          </thead>
          {toggle && (
            <tbody>
              {props.items.map(item => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor: item.isFinal ? (item.barcode ? '#8ce396' : '#ee8f8f') : '#fff',
                    borderBottom: '1px solid #eeeeee',
                    opacity: isCliaming === true ? '0.5' : '1',
                  }}
                >
                  {!!props?.onlyThirdParty && (
                    <td>
                      <div className={styles.chkBox_wrap}>
                        <Checkbox
                          disabled={!!isCliaming}
                          style={{ marginTop: '-12px' }}
                          checked={selectedPackages.findIndex(x => x.id === item.id) >= 0}
                          onChange={() => toggleSellerId(item)}
                        />
                      </div>
                    </td>
                  )}
                  <td className="text-center">
                    <i
                      className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
                      style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
                    ></i>
                  </td>
                  <td>
                    {!!item.barcode &&
                    !item.requestPickupAt &&
                    item?.claimPickupBy?.groupID !== 5 &&
                    item?.type !== 'INTERNAL' &&
                    item?.supplier ? (
                      <h5 style={{ color: '#e22f2f', fontWeight: 'bold' }}>Seller Drop</h5>
                    ) : (
                      ''
                    )}
                    {item?.requestPickupAt ? (
                      <div>
                        <strong>Requested At</strong>
                        <br /> {item?.requestPickupAt}
                      </div>
                    ) : (
                      ''
                    )}
                    {item?.appointedAt ? (
                      <div>
                        <strong>Appointed At</strong>
                        <br /> {item?.appointedAt}
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{item.type === 'THIRD_PARTY' ? 'Third Party' : 'L192'}</td>
                  <td>
                    <div className={styles.badge_wrap}>
                      <div>
                        <Link className="h4" to={`/package/detail/${item.id}`} target="_blank">
                          {item.id.toString().padStart(8, '0')}
                        </Link>
                      </div>
                      {Number(item?.attempt) > 0 && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#e22f2f',
                              display: 'inline-block',
                              margin: '6px 0 0 5px',
                              padding: '0px 5px',
                            }}
                          >
                            Attempt ({item?.attempt})
                          </span>
                        </div>
                      )}
                      {item?.useInsurance === true && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#F94892',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '0px 5px',
                              color: '#fff',
                            }}
                          >
                            {Formatter.beautifyUglyName('Insurance')}
                          </span>
                        </div>
                      )}
                      {item?.isPrepayment === true && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#009432',
                              display: 'inline-block',
                              margin: '6px 0 0 5px',
                              padding: '0px 5px',
                            }}
                          >
                            Pre Payment
                          </span>
                        </div>
                      )}
                      {!!item?.isDropbox && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#eb3b5a',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '0px 5px',
                              color: '#fff',
                            }}
                          >
                            CTD
                          </span>
                        </div>
                      )}
                      {!!item?.hasStock && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#ffa500',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '0px 5px',
                              color: '#fff',
                            }}
                          >
                            EXP
                          </span>
                        </div>
                      )}
                      {!!item?.hasStock && !item?.barcode && (
                        <div onClick={() => window.open(`/package/detail/${item.id}`, '_blank')}>
                          <span
                            style={{
                              cursor: 'pointer',
                              fontSize: 10,
                              background: '#000000',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '0px 5px',
                              color: '#fff',
                            }}
                          >
                            Attach Barcode
                          </span>
                        </div>
                      )}
                      {item?.overSize && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#e65252',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '0px 5px',
                              color: '#fff',
                            }}
                          >
                            Over Size&nbsp;
                            <Tooltip
                              preferredPosition="above"
                              width="wide"
                              content="ចំពោះកញ្ចប់ដែលធំពេក Oversize, Controller ត្រូវហៅដៃគូរដឹកជញ្ជូនមកដឹកឬទៅយកកញ្ចប់នេះ"
                            >
                              <Text fontWeight="bold" variant="bodySm" as="span">
                                <i className="fas fa-question-circle"></i>
                              </Text>
                            </Tooltip>
                          </span>
                        </div>
                      )}
                    </div>
                    <small>
                      Cash to collect:{' '}
                      <strong style={{ fontSize: '14px', color: '#e22f2f' }}>${item.cash?.toFixed(2)}</strong> |
                      Barcode: <strong>{item.barcode}</strong>
                      {item?.barcodeInitial && (
                        <strong style={{ color: '#0052cc' }}>
                          &nbsp; <i>({item?.barcodeInitial})</i>
                        </strong>
                      )}
                    </small>
                    {!!item?.hasReturningItem && (
                      <div>
                        <span
                          style={{
                            fontSize: 12,
                            display: 'inline-block',
                            fontWeight: 'bold',
                            color: 'RED',
                          }}
                        >
                          <i className="fas fa-box-alt"></i> CUSTOMER WILL RETURN A PRODUCT
                        </span>
                      </div>
                    )}
                    {!!item?.returningFromPackageId && (
                      <div>
                        <span
                          style={{
                            fontSize: 13,
                            display: 'inline-block',

                            fontWeight: 'bold',
                            color: '#1C6DD0',
                          }}
                        >
                          <i className="fas fa-hand-holding-box"></i> RETURN FROM PACKAGE #
                          {item?.returningFromPackageId}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <strong>{item.supplier?.name}</strong>
                  </td>
                  <td>
                    <strong>{item.claimDeliveryBy?.name}</strong>
                  </td>
                  <td>
                    {item.customer && (
                      <div>
                        <strong>{item.customer.name}</strong>
                        <br />
                        <span>{item.customer.phoneNumber}</span>
                      </div>
                    )}
                  </td>
                  <td>
                    <strong style={{ fontSize: 14 }}>
                      {item.currentWarehouse?.shortName}
                      &nbsp;&nbsp;
                      <i className="fas fa-arrow-right" />
                      &nbsp;&nbsp; {item.nextWarehouse?.shortName ?? 'Customer'}
                    </strong>
                  </td>
                  {!!props?.onlyThirdParty && (
                    <td>
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={e => {
                          e.stopPropagation();
                          onPrint(item);
                        }}
                      >
                        Print &nbsp;<i className="os-icon os-icon-printer"></i>
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    );
  }
  return null;
}

function renderPackage(items: Graph.Package[] | undefined, onlyThirdParty: boolean) {
  const today = moment(Date.now()).format('YYYY-MM-DD');
  const yesterdays: Graph.Package[] = [];
  const tomorrow: Graph.Package[] = [];
  const morning: Graph.Package[] = [];
  const afternoon: Graph.Package[] = [];
  const evening: Graph.Package[] = [];

  items?.map(x => {
    if (x.appointedAt && !moment(x.appointedAt).isBefore(moment(`${today} 00:00:00`))) {
      if (moment(x.appointedAt).isAfter(moment(`${today} 23:59:59`))) {
        tomorrow.push(x);
      } else if (moment(x.appointedAt).isBefore(`${today} 12:00:00`)) {
        morning.push(x);
      } else if (moment(x.appointedAt).isBefore(`${today} 17:00:00`)) {
        afternoon.push(x);
      } else {
        evening.push(x);
      }
    } else {
      yesterdays.push(x);
    }
    return null;
  });
  const list = [
    { title: 'Yesterday', items: yesterdays },
    { title: '00AM-12PM', items: morning },
    { title: '12PM-5PM', items: afternoon },
    { title: '05PM-12AM', items: evening },
    { title: 'Tomorrow', items: tomorrow },
  ];
  return list.map((l, i) => <Schedule {...l} key={i} onlyThirdParty={onlyThirdParty} />);
}

export default function PackageArrivedHubButtonFilter({
  onlyThirdParty,
  items,
}: {
  onlyThirdParty: boolean;
  items: Graph.Package[] | undefined;
}) {
  const [hubName, setHubName] = useState('All');
  const hubs: {
    title: string;
    total: number;
    isCtd?: number | undefined;
    hasStock?: number;
    hasStockWithBarcode?: number;
  }[] = [
    {
      title: 'All',
      total: items?.length || 0,
      isCtd: items?.filter(x => !!x.isDropbox).length,
      hasStock: items?.filter(x => x.hasStock).length,
      hasStockWithBarcode: items?.filter(x => x.barcode && !!x.hasStock).length,
    },
  ];
  items?.map(x => {
    const title = x?.currentWarehouse?.shortName || 'Little Fashion';
    const index = hubs.findIndex(h => h.title === title);

    if (index < 0) {
      hubs.push({
        title,
        total: 1,
        isCtd: x.isDropbox ? 1 : 0,
        hasStock: x.hasStock ? 1 : 0,
        hasStockWithBarcode: x.hasStock && x.barcode ? 1 : 0,
      });
    } else {
      const hub = hubs[index];
      hubs[index] = {
        title,
        total: hub.total + 1,
        isCtd: Number(hub.isCtd) + Number(x.isDropbox || 0),
        hasStock: Number(hub.hasStock || 0) + (x.hasStock ? 1 : 0),
        hasStockWithBarcode: Number(hub.hasStockWithBarcode || 0) + (x.hasStock && x.barcode ? 1 : 0),
      };
    }
    return null;
  });

  return (
    <>
      <div className={styles.btn_filter_wrap}>
        {hubs.map((h, i) => (
          <div key={i}>
            <Button
              theme={hubName === h.title ? 'primary' : 'secondary'}
              value={h.title}
              onClick={() => setHubName(h.title)}
            >
              {h.title} ({h.total})
              {Number(h.isCtd) > 0 && (
                <span style={{ color: '#eb3b5a', fontSize: '11px' }}> &nbsp;CTD : {h.isCtd}</span>
              )}
              {Number(h.hasStock) > 0 && (
                <span
                  style={{
                    color: 'white',
                    fontSize: '11px',
                    backgroundColor: 'orange',
                    lineHeight: '15px',
                    padding: '0 5px',
                    borderRadius: '0px 10px 10px 0px',
                    marginLeft: '4px',
                  }}
                >
                  &nbsp;EXP : {h.hasStockWithBarcode} / {h.hasStock}&nbsp;
                  <Tooltip
                    preferredPosition="above"
                    width="wide"
                    content={`Express: បានភ្ជាប់ BARCODE ចំនួន ${h.hasStockWithBarcode} / ${h.hasStock} កញ្ចប់។ 
                          បន្ទាប់ពី attach barcode ហើយត្រូវយកមក Scan In.
                          `}
                  >
                    <Text fontWeight="bold" variant="bodySm" as="span">
                      <i className="fas fa-question-circle"></i>
                    </Text>
                  </Tooltip>
                </span>
              )}
            </Button>
          </div>
        ))}
      </div>

      {renderPackage(
        items?.filter(
          x =>
            hubName === 'All' ||
            (hubName === 'Little Fashion' && x.currentWarehouse === null) ||
            x.currentWarehouse?.shortName === hubName,
        ),
        onlyThirdParty,
      )}
    </>
  );
}
