import { Graph } from '../../../generated/graph';
import { UploadInlineFile } from '../../../components/UploadInlineFile';
import { useDefaultValue } from '../../../libs/Common';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { SummaryRowType } from '../PurchaseOrderTransactionScreen';

const QUERY_PURCHASE_ORDE = gql`
  query getPurchaseOrder($id: Int!) {
    purchaseOrder(id: $id) {
      id
      name
      volume
      transactionList {
        id
        productID
        skuID
        size
        color
        qty
        cost
        transactionType
        createdAt
        product {
          dimensionSize
        }
      }
    }
  }
`;

interface Props {
  value: Graph.PurchaseOrderInput;
  onChange: (v: Partial<Graph.PurchaseOrderInput>) => void;
  id?: number;
}

function buildSummary(rows: Graph.StockTransaction[]): SummaryRowType[] {
  const result: SummaryRowType[] = [];
  const hash: { [key: string]: SummaryRowType } = {};

  for (const row of rows) {
    const key = row.productID! + row.size! + row.color + row.cost;
    if (hash[key]) {
      hash[key].qty += row.qty!;
    } else {
      hash[key] = {
        productID: row.productID!,
        color: row.color!,
        size: row.size!,
        qty: row.qty!,
        cost: parseFloat(row.cost!),
        product: row.product || {},
      };
    }
  }

  for (const row of Object.values(hash)) {
    result.push(row);
  }

  return result;
}

export function PurchaseOrderBeforeArrivedPartial({ value, onChange, id }: Props) {
  const { data, loading } = useQuery<Graph.Query>(QUERY_PURCHASE_ORDE, {
    variables: {
      id: Number(id),
    },
    skip: !id,
  });

  let totalVolume = 0;

  if (!loading && data) {
    const summary = buildSummary(data.purchaseOrder?.transactionList || []);
    for (const s of summary) {
      totalVolume += (Number(s.product.dimensionSize) / 1000000) * Number(s.qty);
    }
  }

  return (
    <fieldset>
      <legend>
        <span>Before Arrive</span>
      </legend>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label>Shipping Invoice #</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="string"
                value={useDefaultValue(value.shippingNumber, '')}
                onChange={e => onChange({ ...value, shippingNumber: e.currentTarget.value })}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Volume (CBM)</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="string"
                value={useDefaultValue(value.volumne, '')}
                onChange={e => onChange({ ...value, volumne: e.currentTarget.value })}
              />
            </div>
            {totalVolume > 0 && <small style={{ color: '#666' }}>{totalVolume}</small>}
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Shipping Invoice</label>
            <UploadInlineFile
              value={useDefaultValue(value.shippingFile, undefined)}
              onChange={e => onChange({ ...value, shippingFile: e })}
            />
          </div>
        </div>
      </div>{' '}
    </fieldset>
  );
}
