import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Printing } from '../../libs/Printing';
import { Graph } from '../../generated/graph';
import { PurchaseOrderBasicPartial } from './partials/PurchaseOrderBasicPartial';
import { PurchaseOrderShippingPartial } from './partials/PurchaseOrderShippingPartial';
import { PurchaseOrderPaymentPartial } from './partials/PurchaseOrderPaymentPartial';
import { PurchaseOrderBeforeArrivedPartial } from './partials/PurchaseOrderBeforeArrivedPartial';
import { PurchaseOrderCreditNotePartial } from './partials/PurchaseOrderCreditNotePartial';

const MUTATION = gql`
  mutation UpdatePurchaseOrder($id: Int!, $order: PurchaseOrderInput!) {
    updatePurchaseOrder(id: $id, order: $order)
  }
`;

type Props = {
  purchaseOrder: Graph.PurchaseOrder;
  admin: Graph.Admin;
  id?: number;
};

export function PurchaseOrderEdit({ purchaseOrder, id }: Props) {
  const [input, setInput] = useState<Graph.PurchaseOrderInput>({
    creditAmount: purchaseOrder.creditAmount,
    creditAmountCurrency: purchaseOrder.creditAmountCurrency,
    creditType: purchaseOrder.creditType,
    creditUnit: purchaseOrder.creditUnit,
    exchangeRate: purchaseOrder.exchangeRate,
    invoiceAmount: purchaseOrder.invoiceAmount,
    invoiceAt: purchaseOrder.invoiceAt,
    invoiceFile: purchaseOrder.invoiceFile,
    invoiceNumber: purchaseOrder.invoiceNumber,
    name: purchaseOrder.name,
    orderAmount: purchaseOrder.orderAmount,
    orderAmountCurrency: purchaseOrder.orderAmountCurrency,
    orderDetail: purchaseOrder.orderDetail,
    orderFile: purchaseOrder.orderFile,
    orderQty: purchaseOrder.orderQty,
    paymentFile: purchaseOrder.paymentFile ?? '',
    paymentMethod: purchaseOrder.paymentMethod,
    shippingAmount: purchaseOrder.shippingAmount,
    shippingAmountCurrency: purchaseOrder.shippingAmountCurrency,
    shippingFile: purchaseOrder.shippingFile,
    shippingNumber: purchaseOrder.shippingNumber,
    shippingQty: purchaseOrder.shippingQty,
    supplierFile: purchaseOrder.supplierFile,
    type: purchaseOrder.type,
    tags: (purchaseOrder.tags ?? []).map(t => t.name ?? ''),
    volumne: purchaseOrder.volume ?? '',
  });

  const [update, { data: mutationData, error, loading }] = useMutation<
    Graph.Mutation,
    { id: number; order: Graph.PurchaseOrderInput }
  >(MUTATION);

  const onUpdateClicked = () => {
    // Construct the order variable
    const order: Graph.PurchaseOrderInput = { ...input };

    if (input.paymentMethod === purchaseOrder.paymentMethod) {
      order.paymentMethod = undefined;
      order.invoiceAt = undefined;
    }

    update({
      variables: {
        id: purchaseOrder.id,
        order,
      },
    });
  };

  const onPrintClicked = () => {
    const print = new Printing();
    print.send(`PRINT_COMMAND _PCO_${purchaseOrder.id}, ${purchaseOrder.name}`);
  };

  return (
    <div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      {mutationData && (
        <div className="alert alert-success" role="alert">
          Successfully update
        </div>
      )}

      <PurchaseOrderBasicPartial value={input} onChange={setInput} />
      <PurchaseOrderPaymentPartial value={input} onChange={setInput} />
      <PurchaseOrderShippingPartial value={input} onChange={setInput} />

      <div className="row">
        <div className="col-6">
          <PurchaseOrderBeforeArrivedPartial value={input} onChange={setInput} id={id} />
        </div>
        <div className="col-6">
          <PurchaseOrderCreditNotePartial value={input} onChange={setInput} />
        </div>
      </div>

      <div className="form-buttons-w">
        <button className="btn btn-lg btn-primary" onClick={onUpdateClicked}>
          Save
        </button>
        <button disabled={loading} className="btn btn-lg btn-primary" onClick={onPrintClicked}>
          Print Barcode
        </button>
      </div>
    </div>
  );
}
